"use client";

import { cva } from "class-variance-authority";
import React from "react";
import { Link } from "ui-components";

import { WpPostEntity } from "@/hooks/api/generated";
import { IWPTerm } from "@/src/@types/Wordpress";
import {
  extractTermRelationshipByType,
  getTermMetaValue,
} from "@/utils/wordpress";

interface INewsDetailProps {
  news: WpPostEntity;
  limit?: number;
}

const tagClass = cva(
  "border border-1 rounded-3xl px-2.5 py-1 flex items-center justify-center whitespace-nowrap no-underline",
  {
    variants: {
      tagColor: {
        true: "text-white",
        false: "border-black hover:text-black",
      },
    },
    defaultVariants: {
      tagColor: false,
    },
  }
);

export default function NewsTags({
  news,
  limit,
}: Readonly<INewsDetailProps>): JSX.Element {
  const terms = extractTermRelationshipByType(news, "post_tag") || [];
  const limitedTerms = limit ? terms.slice(0, limit) : terms;

  return (
    <>
      {limitedTerms.map((term: IWPTerm, index: number) => {
        const tagColor = getTermMetaValue(term, "tag_color");
        return (
          <Link
            key={term.term_id || index}
            href={`/news?tag=${encodeURIComponent(term.name)}&page=1`}
            hasHoverEffect={false}
            decoration={false}
            fontSize="base"
            className={tagClass({ tagColor: !!tagColor })}
            style={
              tagColor
                ? { backgroundColor: tagColor, borderColor: tagColor }
                : undefined
            }
          >
            {term.name}
          </Link>
        );
      })}
    </>
  );
}
