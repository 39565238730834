import { VariantProps, cva } from "class-variance-authority";
import { useRouter } from "next/navigation";
import { Button, Heading, Link, Text, ImageWrapper } from "ui-components";

import { PLACEHOLDER_IMAGE } from "@/src/constants/images";
import { OrganizationResponseEntity } from "@/src/hooks/api/generated";

interface IFriendlyFeaturedOrganizationsProps
  extends VariantProps<typeof sectionClasses>,
    VariantProps<typeof headerClasses> {
  organization?: OrganizationResponseEntity;
}

export function FriendlyFeaturedOrganizations({
  organization,
  spacing,
  align,
  header,
}: Readonly<IFriendlyFeaturedOrganizationsProps>): JSX.Element | null {
  const router = useRouter();
  if (!organization) {
    return null;
  }

  const coverImage = organization.organizationImage.find(
    (img) => img?.image?.type === "banner"
  )?.image?.public_id;

  const logoImage = organization.organizationImage.find(
    (img) => img?.image?.type === "logo"
  )?.image?.public_id;

  return (
    <div className={sectionClasses({ spacing, align })}>
      <div className={headerClasses({ header })}>
        <Heading variant="h2">Meet our Inclusive Organizations</Heading>
        <Link href="/organizations">View all Inclusive Organizations</Link>
      </div>
      <div className="bg-primary bg-opacity-10 w-full sm:w-1/2 rounded-md">
        <ImageWrapper
          src={coverImage}
          placeholderUrl={PLACEHOLDER_IMAGE}
          alt="organization logo"
          width={312}
          height={120}
          className="rounded-t-lg w-full"
          isCLDImage
        />
        <div className="flex flex-col gap-y-2.5 items-start p-5">
          <div className="rounded-2xl bg-white text-sm p-1.5">
            Organization of the week
          </div>
          <ImageWrapper
            src={logoImage}
            placeholderUrl={PLACEHOLDER_IMAGE}
            alt="organization logo"
            width={68}
            height={68}
            className="rounded-2xl"
            isCLDImage
          />
          <Heading variant="h2">{organization?.name}</Heading>
          {!!organization?.area && (
            <Text variant="large">{organization?.area?.name}</Text>
          )}
          <div className="flex items-center justify-start w-full mb-2.5 gap-5">
            {!!organization?.amount_of_employees && (
              <div>
                <Heading variant="h2" intent="themePrimary">
                  {organization.amount_of_employees}
                </Heading>
                <Text variant="large">Employees</Text>
              </div>
            )}
            {organization?.total_jobs > 0 && (
              <div>
                <Heading variant="h2" intent="themePrimary">
                  {organization.total_jobs}
                </Heading>
                <Text variant="large">Jobs</Text>
              </div>
            )}
            {organization?.total_reviews > 0 && (
              <div>
                <Heading variant="h2" intent="themePrimary">
                  {organization.total_reviews}
                </Heading>
                <Text variant="large">Reviews</Text>
              </div>
            )}
          </div>
          <Button
            onClick={() => {
              router.push(`/organizations/${organization.slug}/jobs`);
            }}
            className="mb-4 mx-auto"
          >
            View Current Jobs
          </Button>
          <Link
            fontSize="sm"
            className="mx-auto"
            href={`/organizations/${organization.slug}`}
          >
            <>Learn more about {organization.name}</>
          </Link>
        </div>
      </div>
    </div>
  );
}

const sectionClasses = cva("flex flex-col w-full", {
  variants: {
    spacing: {
      large: "gap-y-5 lg:gap-y-10",
      small: "gap-y-5",
    },
    align: {
      center: "items-center",
      left: "items-start",
    },
  },

  defaultVariants: {
    spacing: "large",
    align: "center",
  },
});

const headerClasses = cva("w-full flex", {
  variants: {
    header: {
      multiline: "flex-col gap-y-2.5 items-center",
      inline: "flex-row items-center justify-between",
    },
  },

  defaultVariants: {
    header: "multiline",
  },
});
