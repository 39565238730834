import { VariantProps, cva } from "class-variance-authority";
import { useMemo } from "react";
import { Heading, Link } from "ui-components";

import { WpPostEntity } from "@/src/hooks/api/generated";

import { Slider } from "../slider/Slider";

import { EventCard } from "./EventCard";

interface IMyGworkEventsWPProps extends VariantProps<typeof sectionClasses> {
  title?: string;
  events: WpPostEntity[];
  slidesToShow?: number;
  isWorkpride?: boolean;
}

export function MyGworkEventsWP({
  title,
  events,
  slidesToShow = 3,
  spacing,
  isWorkpride,
}: IMyGworkEventsWPProps): JSX.Element {
  const settings = {
    dots: false,
    infinite: events.length > 1,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: slidesToShow > 1 ? 2 : slidesToShow,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderEventItems = useMemo(
    () =>
      events.map((event) => (
        <EventCard key={event.ID} event={event} isWorkpride={isWorkpride} />
      )),
    [events, isWorkpride]
  );

  return (
    <div className={sectionClasses({ spacing })}>
      {title && (
        <div className="flex flex-col gap-y-2.5 sm:flex-row justify-between w-full items-center px-7 lg:px-0">
          <Heading variant="h2">{title}</Heading>
          <Link href="/events">View All Events</Link>
        </div>
      )}
      <Slider settings={settings}>{renderEventItems}</Slider>
    </div>
  );
}

const sectionClasses = cva("flex flex-col w-full", {
  variants: {
    spacing: {
      large: "gap-y-5 lg:gap-y-10",
      small: "gap-y-5",
    },
  },

  defaultVariants: {
    spacing: "large",
  },
});
