import { ArrowLeftIcon, ArrowRightIcon } from "icons";
import React, { useRef, useState } from "react";
import SlickSlider, { Settings } from "react-slick";

interface ISliderProps {
  settings: Settings;
  children: React.ReactNode;
  playOnHover?: boolean;
}

const SlickButtonFix = (props: {
  children: JSX.Element;
  slideCount?: number;
  currentSlide?: number;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, currentSlide, slideCount, ...others } = props;
  return <div {...others}>{children}</div>;
};

export function Slider({
  settings,
  children,
  playOnHover = false,
}: ISliderProps): JSX.Element {
  const [autoplay, setAutoplay] = useState(false);
  const sliderRef = useRef<SlickSlider | null>(null);

  const updatedSettings = playOnHover
    ? {
        ...settings,
        autoplay,
        autoplaySpeed: 2000,
      }
    : settings;

  const handleMouseEnter = (): void => {
    setAutoplay(true);
    sliderRef.current?.slickPlay();
  };

  const handleMouseLeave = (): void => {
    setAutoplay(false);
    sliderRef.current?.slickPause();
  };

  const handleFocus = (): void => {
    if (!autoplay) {
      handleMouseEnter();
    }
  };

  const handleBlur = (): void => {
    if (autoplay) {
      handleMouseLeave();
    }
  };

  return (
    <div
      {...(playOnHover && {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onMouseOver: handleMouseEnter,
        onMouseOut: handleMouseLeave,
        onFocus: handleFocus,
        onBlur: handleBlur,
      })}
      role="region"
    >
      <SlickSlider
        {...updatedSettings}
        ref={sliderRef}
        className="mx-5 lg:-mx-2.5"
        prevArrow={
          <SlickButtonFix>
            <ArrowLeftIcon className="h-5 w-5" />
          </SlickButtonFix>
        }
        nextArrow={
          <SlickButtonFix>
            <ArrowRightIcon className="h-5 w-5" />
          </SlickButtonFix>
        }
      >
        {children}
      </SlickSlider>
    </div>
  );
}
