export function convertOembedToWpEmbed(html: string): string {
  // Regular expression to match <oembed> tags and capture the URL
  const oembedRegex =
    /<figure class="media"><oembed url="([^"]+)"><\/oembed><\/figure>/gi;

  // Replace <oembed> tag with WordPress embed format
  return html.replace(oembedRegex, (match, url) => {
    const provider = url.includes("youtube.com")
      ? "youtube"
      : url.includes("vimeo.com")
        ? "vimeo"
        : "generic";
    const type =
      provider === "youtube"
        ? "video"
        : provider === "vimeo"
          ? "video"
          : "embed";

    return (
      `<!-- wp:embed {"url":"${url}","type":"${type}","providerNameSlug":"${provider}","responsive":true,"className":"wp-embed-aspect-16-9 wp-has-aspect-ratio"} -->` +
      `<figure class="wp-block-embed is-type-${type} is-provider-${provider} wp-block-embed-${provider} wp-embed-aspect-16-9 wp-has-aspect-ratio">` +
      `<div class="wp-block-embed__wrapper">${url}</div></figure>` +
      "<!-- /wp:embed -->"
    );
  });
}

export function convertWpEmbedToOembed(html: string): string {
  // Updated regular expression to match WordPress embed format with flexibility for different types and providers
  const wpEmbedRegex =
    /<!--\s*wp:embed\s*{[^}]*"url":"([^"]+)","type":"([^"]+)","providerNameSlug":"([^"]+)"[^}]*}\s*-->[\s\S]*?<figure[^>]*>[\s\S]*?<div[^>]*>\s*([^<]+)\s*<\/div>[\s\S]*?<\/figure>\s*<!--\s*\/wp:embed\s*-->/gi;

  // Replace WordPress embed format with <oembed> structure
  return html.replace(
    wpEmbedRegex,
    (match, url) =>
      `<figure class="media"><oembed url="${url}"></oembed></figure>`
  );
}

export function convertWpEmbedToWeb(html: string): string {
  // Updated regular expression to match WordPress embed format with flexibility for different types and providers
  const wpEmbedRegex =
    /<figure[^>]*>[\s\S]*?<div[^>]*>\s*([^<]+)\s*<\/div>[\s\S]*?<\/figure>/gi;

  // Replace WordPress embed format with <oembed> structure
  return html.replace(
    wpEmbedRegex,
    (match, url) =>
      `<figure class="media"><oembed url="${url}"></oembed></figure>`
  );
}
